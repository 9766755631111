import React from 'react';
import { graphql } from 'gatsby';
import Helmet from 'react-helmet';
import SEO from '../../components/SEO';
import Layout from '../../layouts/index';

const About = (props) => {
  const about = props.data.allMarkdownRemark.edges;
  return (
    <Layout bodyClass="page-about">
      <SEO title="About Us - Specializes in Grinding/Abrasive-Polishing Materials" />
      <Helmet>
        <meta
          name="description"
          content="Seaman Industries, Manufacturers of Optical Goods, Barrel-Drum Materials, Grinding-Polishing Materials, Spectacle frames abrasive materials, Spectacle frames polishing materials, as well as media and related supplies"
        />
      </Helmet>
      <div className="intro">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1>About Us</h1>
            </div>
          </div>
        </div>
      </div>

      <div className="container pb-6">
        <div className="row">
          {about.map(edge => (
            <div key={edge.node.frontmatter.path} className="col-12 mb-1">
              <div className="about">
                <div className="about-meta">
                  <h2 className="about-title">{edge.node.frontmatter.title}</h2>
                </div>
                <div
                  className="about-content"
                  dangerouslySetInnerHTML={{ __html: edge.node.html }}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query AboutQuery {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/about/" } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          html
          frontmatter {
            title
            path
          }
        }
      }
    }
  }
`;

export default About;
